import React, { useEffect } from 'react';
import { Button, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';
const ConfirmationDialog = ({
    open,
    onClose,
    onConfirm,
    isSuccess,
    developerDelete,
    handleDeleteDate,
    isOnNoticePeriod,
    handleRetainClick,
    deleteDeveloperName,
    departureOn,
}) => {
    useEffect(() => {
        if (isSuccess === true) {
            onClose();
        }
    }, [isSuccess]);

    return (
        <Modal
            open={open}
            footer={[
                <Button
                    key="back"
                    onClick={onClose}
                >
                    Go Back
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={onConfirm}
                >
                    {developerDelete
                        ? 'Depart'
                        : 'Delete'}
                </Button>,
            ]}
            centered
            destroyOnClose
            width={500}
            closable={false}
        >
            {developerDelete && (
                <div className="flex justify-between mb-7">
                    <h2>Depart Developer</h2>
                    <div className="flex gap-[2px] ">
                        {/* <DatePicker
              onChange={handleDeleteDate}
              placeholder="Select depart date"
              defaultValue={
                departureOn
                  ? dayjs(dayjs(departureOn).format("DD-MM-YYYY"), "DD-MM-YYYY")
                  : dayjs(dayjs().format("DD-MM-YYYY"), "DD-MM-YYYY")
              }
            /> */}
                        <DatePicker
                            onChange={
                                handleDeleteDate
                            }
                            placeholder="Select depart date"
                            defaultValue={
                                departureOn
                                    ? dayjs(
                                          departureOn,
                                          "DD-MM-YYYY",
                                      ).isValid()
                                        ? dayjs(
                                              departureOn,
                                              "DD-MM-YYYY",
                                          )
                                        : null
                                    : dayjs()
                            }
                            format="DD-MM-YYYY"
                        />

                       

                        {isOnNoticePeriod && ( // Conditionally render the Retain button after DatePicker
                            <Button
                                className="bg-green-500 text-white font-bold"
                                onClick={
                                    handleRetainClick
                                }
                            >
                                Retain
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {!developerDelete && (
                <h2 className="mb-7">Delete</h2>
            )}
            {developerDelete ? (
                <p>
                    Are you sure you want to
                    depart{' '}
                    <span className="text-red-500 font-bold">
                        {deleteDeveloperName}
                    </span>
                    ?
                </p>
            ) : (
                <p>
                    Are you sure you want to
                    Delete this ?
                </p>
            )}
            {!developerDelete && (
                <p>
                    No information can be
                    recovered on deletion
                </p>
            )}
        </Modal>
    );
};

export default ConfirmationDialog;
