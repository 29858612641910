import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../../apiclient';
import { toast } from 'react-toastify';

const initialState = {
    isLoading: false,
    users: [],
    searchFilters: {},
    msg: '',
    status: '',
    dataPerPage: 10,
    totalCount: 0,
    currentPage: 0,
    searching: false,
    otherEngagements: [],
};

// export const get_users = createAsyncThunk(
//   "getUsers/get_users",
//   async (data, thunkAPI) => {
//     const { searchFilters } = thunkAPI.getState().getUsers;
//     try {
//       let res = await api.get(
//         `/api/v2/users/search?techStack=${
//           searchFilters?.techStack ? searchFilters?.techStack : ""
//         }&seniority=${
//           searchFilters?.seniority ? searchFilters?.seniority : ""
//         }&group=${
//           searchFilters?.group ? searchFilters?.group : ""
//         }&designation=${
//           searchFilters?.designation ? searchFilters?.designation : ""
//         }&isAvailable=${
//           searchFilters?.isAvailable ? searchFilters?.isAvailable : ""
//         }&secondaryTechStack=${
//           searchFilters?.secondaryTechStack
//             ? searchFilters?.secondaryTechStack
//             : ""
//         }&reportingPm=${
//           searchFilters?.reportingPm ? searchFilters?.reportingPm : ""
//         }&projects=${
//           searchFilters?.projects ? searchFilters?.projects : ""
//         }&location=${
//           searchFilters?.location ? searchFilters?.location : ""
//         }&developerType=${
//           searchFilters?.developerType ? searchFilters?.developerType : ""
//         }&min_billability=${
//           searchFilters?.min_billability ? searchFilters?.min_billability : 0
//         }&max_billability=${
//           searchFilters?.max_billability ? searchFilters?.max_billability : 200
//         }&onboardedDevs=${searchFilters?.onboardedDevs ? searchFilters?.onboardedDevs : ""}
//         &offboardedDevs=${searchFilters?.offboardedDevs ? searchFilters?.offboardedDevs : ""}`,
//         {
//           // params: {
//           //   page: data.currentPage,
//           //   limit: data.dataPerPage,
//           // },
//         }
//       );
//       return res.data;
//     } catch (error) {
//       return error.response;
//     }
//   }
// );

export const get_users = createAsyncThunk(
    'getUsers/get_users',
    async (data, thunkAPI) => {
        const {
            pageCount = 0,
            pageSize = 10,
            developerName,
        } = data;
        const { searchFilters } =
            thunkAPI.getState().getUsers;
        const params = new URLSearchParams({
            techStack:
                searchFilters?.techStack || '',
            seniority:
                searchFilters?.seniority || '',
            group: searchFilters?.group || '',
            designation:
                searchFilters?.designation || '',
            isAvailable:
                searchFilters?.isAvailable || '',
            secondaryTechStack:
                searchFilters?.secondaryTechStack ||
                '',
            reportingPm:
                searchFilters?.reportingPm || '',
            projects:
                searchFilters?.projects || '',
            location:
                searchFilters?.location || '',
            developerType:
                searchFilters?.developerType ||
                '',
            min_billability:
                searchFilters?.min_billability ||
                0,
            max_billability:
                searchFilters?.max_billability ||
                200,
            onboardedDevs:
                searchFilters?.onboardedDevs ||
                '',
            offboardedDevs:
                searchFilters?.offboardedDevs ||
                '',
        });

        const url = `/api/v2/users/search?${params.toString()}`;

        try {
            let res = await api.get(
                `/api/v2/users/search?page=${pageCount}&limit=${pageSize}&keyword=${
                    developerName
                        ? developerName
                        : ''
                }&primaryTechStack=${
                    searchFilters?.primaryTechStack
                        ? searchFilters?.primaryTechStack
                        : ''
                }&seniority=${
                    searchFilters?.seniority
                        ? searchFilters?.seniority
                        : ''
                }&group=${
                    searchFilters?.group
                        ? searchFilters?.group
                        : ''
                }&designation=${
                    searchFilters?.designation
                        ? searchFilters?.designation
                        : ''
                }&isAvailable=${
                    searchFilters?.isAvailable
                        ? searchFilters?.isAvailable
                        : ''
                }&secondaryTechStack=${
                    searchFilters?.secondaryTechStack
                        ? searchFilters?.secondaryTechStack
                        : ''
                }&reportingPm=${
                    searchFilters?.reportingPm
                        ? searchFilters?.reportingPm
                        : ''
                }&projects=${
                    searchFilters?.projects
                        ? searchFilters?.projects
                        : ''
                }&location=${
                    searchFilters?.location
                        ? searchFilters?.location
                        : ''
                }&developerType=${
                    searchFilters?.developerType
                        ? searchFilters?.developerType
                        : ''
                }&min_billability=${
                    searchFilters?.min_billability
                        ? searchFilters?.min_billability
                        : 0
                }&max_billability=${
                    searchFilters?.max_billability
                        ? searchFilters?.max_billability
                        : 200
                }&techstackCondition=${
                    searchFilters?.techstackCondition
                        ? searchFilters?.techstackCondition
                        : 'and'
                }`,
                {
                    // params: {
                    //   page: data.currentPage,
                    //   limit: data.dataPerPage,
                    // },
                },
            );
            return res.data;
        } catch (error) {
            console.error('API Error:', error);
            return thunkAPI.rejectWithValue(
                error.response.data,
            );
        }
    },
);

export const get_users_client = createAsyncThunk(
    'getUsers/get_users_client',
    async (data, thunkAPI) => {
        const { searchFilters } =
            thunkAPI.getState().getUsers;
        try {
            let res = await api.get(
                `/api/v2/clientRequirement/filter?&techStack=${
                    searchFilters?.techStack
                        ? searchFilters?.techStack
                        : ''
                }&seniority=${
                    searchFilters?.seniority
                        ? searchFilters?.seniority
                        : ''
                }&group=${
                    searchFilters?.group
                        ? searchFilters?.group
                        : ''
                }&designation=${
                    searchFilters?.designation
                        ? searchFilters?.designation
                        : ''
                }&isAvailable=${
                    searchFilters?.isAvailable
                        ? searchFilters?.isAvailable
                        : ''
                }&secondaryTechStack=${
                    searchFilters?.secondaryTechStack
                        ? searchFilters?.secondaryTechStack
                        : ''
                }&reportingPm=${
                    searchFilters?.reportingPm
                        ? searchFilters?.reportingPm
                        : ''
                }&projects=${
                    searchFilters?.projects
                        ? searchFilters?.projects
                        : ''
                }
        `,
                {
                    // params: {
                    //   page: data.currentPage,
                    //   limit: data.dataPerPage,
                    // },
                },
            );

            return res.data;
        } catch (error) {
            return error.response;
        }
    },
);

export const delete_user = createAsyncThunk(
    'getUsers/delete_user',
    async (data) => {
        try {
            let res = await api.delete(
                data.deleteQuery,
            );
            return res.data;
        } catch (error) {
            return error?.response;
        }
    },
);

export const search_user = createAsyncThunk(
    'api/v2/users/search',
    async (data) => {
        // const { pageCount = 0, pageSize = 10 } =
        //     data;
        // console.log(pageCount, pageSize);
        try {
            let res = await api.get(
                `${data.url}`,
                {
                    params: data?.data,
                },
            );

            return res?.data;
        } catch (error) {
            return error?.response;
        }
    },
);

export const getdeveloperOnboardDetailsFilter =
    createAsyncThunk(
        'get_users/getdeveloperOnboardDetailsFilter',
        async (
            data,
            { rejectWithValue, getState },
        ) => {
            const state = getState();
            const searchFilters =
                state.getUsers.searchFilters;
            try {
                let response = await api.get(
                    `api/v2/users/getOnboardingOffboardingData?&fullName=${
                        searchFilters?.selectedDeveloper
                            ? searchFilters?.selectedDeveloper
                            : ''
                    }&projects=${
                        searchFilters?.selectedProject
                            ? searchFilters?.selectedProject
                            : ''
                    }&POC=${
                        searchFilters?.selectedPoc
                            ? searchFilters?.selectedPoc
                            : ''
                    }&status=${
                        searchFilters?.selectedStatus
                            ? searchFilters?.selectedStatus
                            : ''
                    }`,
                );

                return response.data;
            } catch (error) {
                return rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const search_user_client =
    createAsyncThunk(
        '/api/v2/clientRequirement/filter',
        async (data) => {
            try {
                if (data.url) {
                    let res = await api.get(
                        `${data.url}`,
                        {
                            params: data.data,
                        },
                    );
                    return res?.data;
                }
            } catch (error) {
                return error.response;
            }
        },
    );

export const assign_group = createAsyncThunk(
    'getUsers/assign_group',
    async (data) => {
        const assign_group_url =
            '/developer/assignGroup';
        try {
            let res = await axios.post(
                assign_group_url,
                data,
            );
            return res.data;
        } catch (error) {
            return error.response;
        }
    },
);

export const retainDepartedDeveloper =
    createAsyncThunk(
        'getUsers/retainDepartedDeveloper',
        async (data, thunkAPI) => {
            try {
                let response = await api.put(
                    `api/v2/users/edit-user-departure?action=${data.action}&date=${data.date}&id=${data.id}`,
                );
                return response.data;
            } catch (error) {
                thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const changeAvailability =
    createAsyncThunk(
        'getUsers/changeAvailability',
        async (data, thunkAPI) => {
            try {
                let response = await api.get(
                    `api/v2/users/changeAvailability?userId=${data.userId}&projectId=${data.projectId}`,
                );
                return response.data;
            } catch (error) {
                thunkAPI.rejectWithValue(
                    error.response.data,
                );
            }
        },
    );

export const updateAvailability =
    createAsyncThunk(
        'getUsers/updateAvailability',
        async (data, thunkAPI) => {
            try {
                let response = await api.put(
                    `api/v2/users/confirmChangeAvailability`,
                    data,
                );

                toast.success(
                    response.data.message,
                );

                return response.data;
            } catch (error) {
                thunkAPI.rejectWithValue(
                    error.response.data,
                );
                toast.error(
                    error.response.data.message,
                );
            }
        },
    );

export const updateBillability = createAsyncThunk(
    'getUsers/updateBillability',
    async (data, thunkAPI) => {
        try {
            let response = await api.put(
                `api/v2/users/ConfirmEditBillability`,
                data,
            );

            toast.success(response.data.message);

            return response.data;
        } catch (error) {
            thunkAPI.rejectWithValue(
                error.response.data,
            );
            toast.error(
                error.response.data.message,
            );
        }
    },
);

export const changeBillability = createAsyncThunk(
    'getUsers/changeBillability',
    async (data, thunkAPI) => {
        console.log(data);
        try {
            let response = await api.get(
                `api/v2/users/editBillability?userId=${data.userId}&projectId=${data.projectId}`,
                // `api/v2/users/editBillability`,
            );
            return response.data;
        } catch (error) {
            thunkAPI.rejectWithValue(
                error.response.data,
            );
            toast.error(
                error.response.data.message,
            );
        }
    },
);

const getUsersSlice = createSlice({
    name: 'getUsers',
    initialState,
    reducers: {
        setFilterParameters(state, action) {
            state.searchFilters = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setDataPerPage(state, action) {
            state.dataPerPage = action.payload;
        },
    },

    extraReducers: {
        [get_users.pending]: (state) => {
            state.isLoading = true;
        },
        [get_users.fulfilled]: (
            state,
            action,
        ) => {
            state.msg = action.payload.msg;
            if (
                Array.isArray(action.payload.data)
            ) {
                state.users =
                    action.payload?.data;
            }
            state.isLoading = false;
            state.totalCount =
                action?.payload?.totalCount;
        },
        [get_users.rejected]: (state) => {
            state.isLoading = false;
        },

        [get_users_client.pending]: (state) => {
            state.isLoading = true;
        },
        [get_users_client.fulfilled]: (
            state,
            action,
        ) => {
            state.msg = action.payload.msg;
            if (
                Array.isArray(action.payload.data)
            ) {
                state.users =
                    action.payload?.data;
            }
            state.isLoading = false;
            state.totalCount =
                action?.payload?.count;
        },
        [get_users_client.rejected]: (state) => {
            state.isLoading = false;
        },
        [changeAvailability.fulfilled]: (
            state,
            action,
        ) => {
            state.otherEngagements =
                action.payload.data;
            state.isLoading = false;
        },
        [changeAvailability.pending]: (state) => {
            state.isLoading = true;
        },
        [changeAvailability.rejected]: (
            state,
        ) => {
            state.isLoading = false;
        },
        [changeBillability.pending]: (state) => {
            state.isLoading = true;
        },
        [changeBillability.fulfilled]: (
            state,
            action,
        ) => {
            state.msg = action.payload.msg;
            state.isLoading = false;
        },
        [changeBillability.rejected]: (state) => {
            state.isLoading = false;
        },
        [delete_user.pending]: (state) => {
            state.isLoading = true;
        },
        [delete_user.fulfilled]: (
            state,
            action,
        ) => {
            state.msg = action.payload.msg;
            state.isLoading = false;
        },
        [delete_user.rejected]: (state) => {
            state.isLoading = false;
        },
        [search_user.pending]: (state) => {
            state.searching = true;
            state.users = [];
        },
        [search_user.fulfilled]: (
            state,
            action,
        ) => {
            state.users = action.payload.data;
            state.totalCount =
                action?.payload?.totalCount;
            state.isLoading = false;
            state.searching = false;
        },
        [search_user_client.pending]: (state) => {
            state.searching = true;
            state.users = [];
        },
        [search_user_client.fulfilled]: (
            state,
            action,
        ) => {
            state.users =
                action.payload?.resp?.filterClient;
            state.totalCount =
                action?.payload?.count;
            state.isLoading = false;
            state.searching = false;
        },

        [assign_group.fulfilled]: (
            state,
            action,
        ) => {},
    },
});

export const {
    setFilterParameters,
    setCurrentPage,
    setDataPerPage,
    saveSelectedFilterValuesToRedux,
} = getUsersSlice.actions;

export default getUsersSlice.reducer;
