import {
    Button,
    DatePicker,
    Modal,
    Table,
    Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import dayjs from 'dayjs';

const ConfirmBillabilityModal = ({
    open,
    onClose,
    name,
    updateBillabilityHandler,
}) => {
    console.log(name);
    return (
        <Modal
            open={open}
            onCancel={onClose}
            width={500}
            // bodyStyle={
            //     isLoading
            //         ? { maxHeight: '30vh' }
            //         : {}
            // }
            footer={[
                <Button
                    key="back"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => {
                        updateBillabilityHandler();
                        onClose();
                    }}
                >
                    Confirm
                </Button>,
            ]}
        >
            <p style={{ marginTop: '20px' }}>
                Are you sure you want to change
                the billability of    
                <span className="text-red-500 font-bold">
                          {name}
                </span>
                ?
            </p>
        </Modal>
    );
};

export default ConfirmBillabilityModal;
