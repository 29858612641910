import * as yup from 'yup';

const CreateProjectSchema = yup.object({
    projectName: yup
        .string()
        .required('Project name is required')
        .matches(
            /^([^\s]*[A-Za-z0-9]\s*)*$/,
            'Enter valid data ',
        ),
    clientName: yup
        .string()
        .required('Client name is required'),
    startDate: yup
        .date()
        .typeError('Enter a valid date')
        .required('Start date is required'),
    // startDate: yup
    //   .date("enter a valid date")
    //   .nullable()
    //   .transform((curr, orig) =>
    //     orig === "" || orig === "Invalid Date" ? null : curr
    //   )
    //   .required("Date of Joining Required"),
    // estimatedEndDate: yup.string().required("Estimated end date is required"),
    typeOfProject: yup
        .string()
        .required('Project type is required')
        .nullable(),
    // demoUrls: yup
    //   .string()
    //   .required("Demo URL is required")
    //   .matches(
    //     /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    //     "Enter a valid url"
    //   ),
    // invoice schema
    invoice: yup
        .string()
        .required('Invoice is required')
        .nullable(),
    clientPointOfContact: yup
        .string()
        .nullable()
        .matches(
            /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$|^[\w\.-]+@[\w\.-]+\.\w+$/,

            'Enter a valid mobile number or email address',
        ),
    status: yup
        .string()
        .required('Status is required')
        .nullable(),
    techStack: yup
        .array()
        .min(1, 'Tech Stack is required'),
    projectFund: yup
        .string()
        .required('Project Fund is required')
        .nullable(),
    projectTier: yup
        .string()
        .required('Project Tier is required')
        .nullable(),
    salesPOC: yup
        .mixed()
        .required('Sales POC Required')
        .nullable(),
    clientPointOfContact: yup
        .string()
        .required('contact details is required')
        .nullable(),

    totalProjectHours: yup
        .number()
        .when('typeOfProject', {
            is:
                'Fixed Scope Budget' ||
                'Fixed Scope Premium',
            then: yup
                .number()
                .required(
                    'Total Man Hour is required',
                )
                .min(0, 'Minimum value is 0%')

                .typeError(
                    'Please enter a valid number',
                ),
        })
        .notRequired(),
});

export default CreateProjectSchema;
