import React, {
    useEffect,
    useState,
} from 'react';
import {
    Button,
    Stack,
    Tooltip,
} from '@mui/material';
import '../../Assets/CSS/Developer/Developer.css';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { useDebounce } from 'use-debounce';
import { get_user } from '../../Redux/Slices/Auth/RegDevSlice';

import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
    get_users,
    delete_user,
    search_user,
    setCurrentPage,
    setDataPerPage,
    retainDepartedDeveloper,
} from '../../Redux/Slices/getUsersSlice';
import { DataGrid } from '@mui/x-data-grid';
import LoadingSpinner from '../../utils/LoadingSpinner';
import DeveloperFilter from './DevelopersFilter';
import styles from '../../Assets/CSS/Developer/Developer.module.css';
import moment from 'moment/moment';
import { Box } from '@mui/system';
import ConfirmationDialog from '../../utils/ConfirmationDialog';
import { toast } from 'react-toastify';
import DeletedDevFilter from './DeletedDevFilter';
import EditDevModal from './EditDevModal';
import { makeStyles } from '@mui/styles';

import ActionModal from '../../utils/ActionModal';
import dayjs from 'dayjs';
const Developer = () => {
    let deleteQuery = 'api/v2/users/delete-user/';
    const dispatch = useDispatch();
    const [deleted, setDeleted] = useState(false);
    const [
        openEditDevModal,
        setOpenEditDevModal,
    ] = useState(false);
    const [
        openConfirmDialog,
        setOpenConfirmDialog,
    ] = useState(false);
    const [openActionModal, setOpenActionModal] =
        useState(false);
    const [isSuccess, setIsSuccess] =
        useState(false);
    const [userId, setUserId] = useState('');
    const [deleteDate, setDeleteDate] = useState(
        dayjs().format('DD-MM-YYYY'),
    );
    const [developerDelete, setDeveloperDelete] =
        useState(true);
    const [
        isOnNoticePeriod,
        setIsOnNoticePeriod,
    ] = useState(false);
    const [departureOn, setDepartureOn] =
        useState('');

    // pagecount
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const engagementTypes = [
        'Onboarded',
        'OnReplaced',
        'OnObservation',
        'Temporary',
    ];

    const {
        isLoading,
        users,
        dataPerPage,
        totalCount,
        currentPage,
        searching,
        searchFilters,
        onClose,
    } = useSelector((store) => store.getUsers);
    const role = localStorage.getItem(
        'designation',
    );
    const { userData, isLoading: modalLoading } =
        useSelector((store) => store.regDev);

    const [developerName, setDeveloperName] =
        useState('');

    //  search debounce
    const [searchValue] = useDebounce(
        developerName,
        2000,
    );

    const query = `/api/v2/users/search?isDeleted=false&currentPage=${currentPage}&limit=${dataPerPage}`;
    useEffect(() => {
        dispatch(get_users(query));
    }, [
        currentPage,
        dataPerPage,
        openEditDevModal,
    ]);

    useEffect(() => {
        dispatch(
            get_users({
                pageCount: 0,
                pageSize: 10,
                developerName: searchValue,
            }),
        );
    }, [searchValue]);

    // useEffect(() => {
    //     const url = 'api/v2/users/search';
    //     dispatch(
    //         search_user({
    //             url,
    //             data: searchFilters,
    //         }),
    //     );
    // }, [searchFilters]);

    const handleDelete = (e) => {
        setIsSuccess(false);
        setOpenConfirmDialog(true);
    };

    const handleDeleteDate = (value) => {
        const date =
            dayjs(value).format('DD-MM-YYYY');
        setDeleteDate(date);
    };

    const editModalHandler = (e) => {
        setOpenActionModal(false);
        e.stopPropagation();
        dispatch(get_user(userId))?.then(
            (res) => {
                setOpenEditDevModal(true);
            },
        );
    };

    const useStyles = makeStyles({
        actionColumn: {
            // position: "sticky",
            left: '0', // Adjust the distance from the left as needed
            zIndex: 10,
            backgroundColor: 'white',
            boxShadow:
                '0px 2px 4px rgba(0, 0, 0, 0.8)',
        },

        actionHeader: {
            position: 'sticky !important',
            left: '0', // Adjust the distance from the left as needed
            zIndex: 10,
            backgroundColor: '#FAFAFA',
            color: 'black',
            boxShadow:
                '0px 2px 4px rgba(0, 0, 0, 0.8)',
        },
    });

    const classes = useStyles();

    function convertCamelCaseToNormal(string) {
        const words = string
            .split(/(?=[A-Z])/)
            .join(' ');
        return (
            words.charAt(0).toUpperCase() +
            words.slice(1)
        );
    }

    const columns = [
        {
            field: 'fullName',
            headerName: 'Full name',
            width: 150,
            renderCell: (params) => {
                const myProps = {
                    id: params.row.objId,
                    name: params.row.fullName,
                    projects: params.row.projects,
                };

                const textStyle = {
                    fontSize: '13px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: params.row
                        .isOnNoticePeriod
                        ? 'red'
                        : 'black',
                };

                return (
                    <Tooltip
                        title={params.value}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                            style={textStyle}
                        >
                            <Link
                                to="/dashboard/developer/details"
                                style={textStyle}
                                state={myProps}
                            >
                                {params?.value}
                            </Link>
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'isAvailable',
            headerName: 'Availability',
            width: 120,
            renderCell: (params) => {
                const normalString =
                    convertCamelCaseToNormal(
                        params.value,
                    );
                const getStatusStyles = (
                    status,
                ) => {
                    if (status === 'Available') {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#178C45',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status === 'Enagaged'
                    ) {
                        // Fixed typo from "enagaged" to "engaged"
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#0387FF',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status ===
                        'mayBeAvailable'
                    ) {
                        // Fixed typo from "mayBeAvailable"
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#38ABDD',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status === 'notAvailable'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#D9534F',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status === 'yetToBeHired'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#5BC0DE',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status ===
                        'offerLetterReleased'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#FFC107',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status ===
                        'offerLetterAccepted'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#28A745',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status === 'underTraining'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#17A2B8',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status === 'backedOut'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#DC3545',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    } else if (
                        status === 'Consultant'
                    ) {
                        return {
                            border: '1px solid #F0F0F0',
                            backgroundColor:
                                '#F0F0F0',
                            color: '#007BFF',
                            borderRadius: '16px',
                            padding: '3px 8px',
                        };
                    }
                    return {};
                };

                return (
                    <Tooltip
                        title={convertCamelCaseToNormal(
                            params.value,
                        )}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                            style={{
                                ...getStatusStyles(
                                    params.value,
                                ),
                            }}
                        >
                            {normalString}
                            {params.value ===
                                'Available' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#178C45',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'Enagaged' && ( // Fixed typo from "enagaged"
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#0387FF',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'mayBeAvailable' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#38ABDD',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'notAvailable' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#D9534F',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'yetToBeHired' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#5BC0DE',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'offerLetterReleased' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#FFC107',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'offerLetterAccepted' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#28A745',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'underTraining' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#17A2B8',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}

                            {params.value ===
                                'backedOut' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#DC3545',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                            {params.value ===
                                'Consultant' && (
                                <span
                                    className={
                                        styles.dot
                                    }
                                    style={{
                                        backgroundColor:
                                            '#F0F0F0',
                                        color: '#007BFF',
                                        borderRadius:
                                            '16px',
                                    }}
                                />
                            )}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'isAvailableFrom',

            headerName: 'Is Available From',

            width: 150,
            headerAlign: 'center',
            align: 'center',

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    {params.row.isAvailable ===
                        'Available' ||
                    params.row.isAvailable ===
                        'mayBeAvailable' ? (
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            {params.value}
                        </span>
                    ) : (
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            NA
                        </span>
                    )}
                </Tooltip>
            ),
        },
        {
            field: 'billability',

            headerName: 'Billability',

            width: 100,
            headerAlign: 'center',
            align: 'center',

            renderCell: (params) => {
                const formattedInfo =
                    params.row.combineBillability
                        .map(
                            (info) =>
                                `{${info.projectName}-${info.billability}}`,
                        )
                        .join(', ');
                return (
                    <Tooltip
                        title={formattedInfo}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            {params.value}%
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'projects',

            headerName: 'Projects',

            width: 150,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'engagementType',

            headerName: 'Temporary Project',

            width: 150,

            renderCell: (params) => {
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>;
            },
        },
        {
            field: 'reportingPm',

            headerName: 'Project Manager',

            width: 150,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'reportingManager',

            headerName: 'Reporting Manager',

            type: 'string ',

            width: 150,

            headerAlign: 'center',
            align: 'center',

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        role === 'SALES'
            ? ''
            : {
                  field: 'action',
                  headerName: 'Action',
                  width: 200,
                  headerAlign: 'center',
                  align: 'center',
                  sortable: false,
                  headerClassName:
                      classes.actionHeader,
                  cellClassName:
                      classes.actionColumn,
                  renderCell: (params) => {
                      return (
                          <div>
                              <Button
                                  style={{
                                      border: 'none',
                                  }}
                              >
                                  <IconButton
                                      aria-label="more"
                                      aria-controls="action-menu"
                                      aria-haspopup="true"
                                      onClick={() => {
                                          setOpenActionModal(
                                              true,
                                          );
                                          setUserId(
                                              params
                                                  ?.row
                                                  ?.objId,
                                          );
                                          get_user(
                                              params
                                                  ?.row
                                                  ?.objId,
                                          );
                                          setIsOnNoticePeriod(
                                              params
                                                  ?.row
                                                  ?.isOnNoticePeriod,
                                          );
                                          setDeleteDeveloperName(
                                              params
                                                  ?.row
                                                  ?.fullName,
                                          );
                                          setDepartureOn(
                                              params
                                                  ?.row
                                                  ?.departureOn
                                                  ? params
                                                        ?.row
                                                        ?.departureOn
                                                  : '',
                                          );
                                      }}
                                  >
                                      <MoreVertIcon />
                                  </IconButton>
                              </Button>
                          </div>
                      );
                  },
              },
        {
            field: 'techStack',

            headerName: 'Tech Stack',

            width: 100,
            align: 'center',
            headerAlign: 'center',

            renderCell: (params) => {
                const techStack =
                    params.value === undefined ||
                    ''
                        ? 'N/A'
                        : params.value;

                return (
                    <Tooltip
                        title={techStack}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            {techStack}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'designation',

            headerName: 'Designation',

            width: 200,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'experience',

            headerName: 'Experience',

            type: 'string ',

            width: 150,
            headerAlign: 'center',
            align: 'center',

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'location',
            headerName: 'Base Location',
            width: 150,
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value ===
                            undefined || ''
                            ? 'N/A'
                            : params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'workLocation',
            headerName: 'Work Location',
            width: 150,
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value ===
                            undefined || ''
                            ? 'N/A'
                            : params.value}
                    </span>
                </Tooltip>
            ),
        },

        {
            field: 'remarks',

            headerName: 'Remarks',

            width: 100,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },

        {
            field: 'group',
            headerName: 'Group',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },

        {
            field: 'seniority',

            headerName: 'Seniority',

            width: 100,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },

        {
            field: 'phoneNumber',

            headerName: 'Phone No.',

            type: 'string',

            width: 110,

            renderCell: (params) => {
                const phoneNumber =
                    params.value === ''
                        ? 'N/A'
                        : params.value;

                return (
                    <Tooltip
                        title={phoneNumber}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            {phoneNumber}
                        </span>
                    </Tooltip>
                );
            },
        },

        {
            field: 'emergencyContactNumber',

            headerName: 'Emergency Number',

            type: 'string',

            width: 110,

            renderCell: (params) => {
                const eemergencyCn =
                    params?.value === '' ||
                    params?.value === undefined
                        ? 'N/A'
                        : params.value;

                return (
                    <Tooltip
                        title={eemergencyCn}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            {eemergencyCn}
                        </span>
                    </Tooltip>
                );
            },
        },

        {
            field: 'email',

            headerName: 'Email',

            width: 100,

            renderCell: (params) => {
                const email =
                    params.value === ''
                        ? 'N/A'
                        : params.value;

                return (
                    <Tooltip
                        title={email}
                        placement="right"
                        arrow
                    >
                        <span
                            className={
                                styles.tooltip
                            }
                        >
                            {email}
                        </span>
                    </Tooltip>
                );
            },
        },

        {
            field: 'joiningDate',

            headerName: 'Joining Date',

            width: 150,
            align: 'center',
            headerAlign: 'center',
        },

        {
            field: 'profileLink',

            headerName: 'Profile-link',

            width: 100,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <a
                        className={styles.tooltip}
                        href={params?.value}
                        target="_blank"
                    >
                        {params?.value
                            ? 'View-Profile'
                            : 'NA'}
                    </a>
                </Tooltip>
            ),
        },

        {
            field: 'developerType',

            headerName: 'Developer type',

            width: 100,

            renderCell: (params) => (
                <Tooltip
                    title={params.value}
                    placement="right"
                    arrow
                >
                    <span
                        className={styles.tooltip}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
    ];

    // const rows = users
    //     ?.filter(
    //         (user) => user.isDeleted === deleted,
    //     )
    //     ?.map((user) => {
    //         const joinDate = moment(
    //             user?.joiningDate,
    //             'YYYY-MM-DDTHH:mm:ssZ',
    //         ).format();
    //         const currentDate = moment().format(
    //             'YYYY-MM-DDTHH:mm:ssZ',
    //         );
    //         const experienceAfterJoining =
    //             Math.floor(
    //                 moment(currentDate).diff(
    //                     moment(joinDate),
    //                     'months',
    //                     true,
    //                 ),
    //             );
    //         const totalExperience =
    //             user?.workingExperienceInMonths +
    //             experienceAfterJoining;
    //         const years = Math.floor(
    //             totalExperience / 12,
    //         );
    //         const months =
    //             totalExperience - years * 12;
    //         const yearString =
    //             years === 0
    //                 ? ''
    //                 : years === 1
    //                 ? years + ' Year '
    //                 : years + ' Years ';
    //         const monthString =
    //             months === 0
    //                 ? years === 0
    //                     ? '0 Month'
    //                     : ''
    //                 : months === 1
    //                 ? months + ' Month '
    //                 : months + ' Months ';

    //         return {
    //             empId: user?.empId,
    //             fullName: user?.fullName,
    //             location: user?.location,
    //             workLocation:
    //                 user?.workLocation === 'CS'
    //                     ? `${user?.workLocation} -${user?.workLocationCity}`
    //                     : user?.workLocation,
    //             isAvailableFrom:
    //                 user?.isAvailableFrom
    //                     ? dayjs(
    //                           user?.isAvailableFrom,
    //                       ).format('DD-MM-YYYY')
    //                     : 'NA',
    //             techStack:
    //                 user?.primaryTechStack ??
    //                 'N/A',
    //             projects:
    //                 user?.projects?.length === 0
    //                     ? 'N/A'
    //                     : user?.projects
    //                           .filter(
    //                               (project) =>
    //                                   project.engagementType !==
    //                                   'Temporary',
    //                           )
    //                           ?.map(
    //                               (project) =>
    //                                   project
    //                                       ?.projectDetail
    //                                       ?.projectName,
    //                           )
    //                           .join(',') || 'N/A',

    //             workingExperienceInMonths:
    //                 user?.workingExperienceInMonths,
    //             experience:
    //                 yearString + monthString,

    //             phoneNumber: user?.phoneNumber,
    //             secondaryTechStack:
    //                 user?.secondaryTechStack !==
    //                 undefined
    //                     ? user
    //                           ?.secondaryTechStack[0]
    //                           ?.name ?? 'N/A'
    //                     : 'N/A',
    //             email: user?.email,
    //             remarks: user?.remarks
    //                 ? user?.remarks
    //                 : 'N/A',
    //             emergencyContactNumber:
    //                 user?.emergencyContactNumber,
    //             designation:
    //                 user?.designation?.length ===
    //                 0
    //                     ? 'N/A'
    //                     : user?.designation &&
    //                       user?.designation[0]
    //                           ?.name,
    //             profileLink:
    //                 user?.profileLink?.length ===
    //                 0
    //                     ? 'N/A'
    //                     : user?.profileLink,
    //             developerType:
    //                 user?.developerType
    //                     ?.length === 0
    //                     ? 'InHouse'
    //                     : user?.developerType,
    //             group:
    //                 user?.group?.length === 0
    //                     ? 'N/A'
    //                     : user?.group[0]
    //                           ?.groupName,

    //             joiningDate:
    //                 moment(
    //                     user?.joiningDate,
    //                 )?.format('DD/MM/YYYY') ===
    //                 'Invalid date'
    //                     ? 'N/A'
    //                     : moment(
    //                           user?.joiningDate,
    //                       )?.format('DD/MM/YYYY'),

    //             seniority: user?.seniority,
    //             id: user?.email + '_' + user?._id,
    //             objId: user?._id,
    //             isAvailable:
    //                 user?.isAvailable === null
    //                     ? 'N/A'
    //                     : user?.isAvailable,
    //             isDeleted: user?.isDeleted,
    //             reportingPm:
    //                 user?.pms?.length === 0
    //                     ? 'N/A'
    //                     : user?.pms?.join(', '),
    //             billability: user?.billability,
    //             isOnNoticePeriod:
    //                 user?.isOnNoticePeriod,
    //             departureOn: user?.departureOn,
    //             reportingManager:
    //                 user?.reportingManager
    //                     ?.length > 0
    //                     ? user
    //                           ?.reportingManager[0]
    //                           .fullName
    //                     : 'NA',

    //             engagementType:
    //                 user?.projects?.length === 0
    //                     ? 'N/A'
    //                     : user?.projects
    //                           .filter(
    //                               (project) =>
    //                                   project.engagementType ===
    //                                   'Temporary',
    //                           )
    //                           ?.map(
    //                               (project) =>
    //                                   project
    //                                       ?.projectDetail
    //                                       ?.projectName,
    //                           )
    //                           .join(',') || 'N/A',
    //         };
    //     });

    const rows = users
        ?.filter(
            (user) => user?.isDeleted === deleted,
        )
        .map((user) => {
            const joinDate = moment(
                user?.joiningDate,
                'YYYY-MM-DDTHH:mm:ssZ',
            );
            const currentDate = moment();
            const experienceAfterJoining =
                Math.floor(
                    currentDate.diff(
                        joinDate,
                        'months',
                        true,
                    ),
                ) || 0;
            const totalExperience =
                (user?.workingExperienceInMonths ||
                    0) + experienceAfterJoining;
            const years = Math.floor(
                totalExperience / 12,
            );
            const months = totalExperience % 12;

            const experienceString =
                `${
                    years > 0
                        ? `${years} Year${
                              years > 1 ? 's' : ''
                          }`
                        : ''
                } ${
                    months > 0
                        ? `${months} Month${
                              months > 1
                                  ? 's'
                                  : ''
                          }`
                        : ''
                }`.trim() || '0 Month';

            const projects =
                user?.projects
                    ?.filter(
                        (project) =>
                            project?.engagementType !==
                            'Temporary',
                    )
                    .map(
                        (project) =>
                            project?.projectDetail
                                ?.projectName,
                    )
                    .join(', ') || 'N/A';

            const temporaryEngagement =
                user?.projects
                    ?.filter(
                        (project) =>
                            project?.engagementType ===
                            'Temporary',
                    )
                    .map(
                        (project) =>
                            project?.projectDetail
                                ?.projectName,
                    )
                    .join(', ') || 'N/A';

            const combinedProjectInfo =
                user?.billableProjects?.map(
                    (project) => {
                        const projectDetails =
                            user?.billableProjectDetails?.find(
                                (detail) =>
                                    detail?.projectID ===
                                    project?.projectID,
                            );

                        return {
                            projectName:
                                projectDetails?.projectName ??
                                'Unknown',
                            billability:
                                project?.billability ??
                                'Unknown', //
                        };
                    },
                );

            return {
                empId: user?.empId || 'N/A',
                fullName: user?.fullName || 'N/A',
                location: user?.location || 'N/A',
                workLocation:
                    user?.workLocation === 'CS'
                        ? `${
                              user?.workLocation
                          } - ${
                              user?.workLocationCity ||
                              ''
                          }`
                        : user?.workLocation ||
                          'N/A',
                isAvailableFrom:
                    user?.isAvailableFrom
                        ? dayjs(
                              user?.isAvailableFrom,
                          ).format('DD-MM-YYYY')
                        : 'N/A',
                techStack:
                    user?.primaryTechStack ||
                    'N/A',
                projects,
                workingExperienceInMonths:
                    user?.workingExperienceInMonths ||
                    0,
                experience: experienceString,
                phoneNumber:
                    user?.phoneNumber || 'N/A',
                secondaryTechStack:
                    user?.secondaryTechStack?.[0]
                        ?.name || 'N/A',
                email: user?.email || 'N/A',
                remarks: user?.remarks || 'N/A',
                emergencyContactNumber:
                    user?.emergencyContactNumber ||
                    'N/A',
                designation:
                    user?.designation?.[0]
                        ?.name || 'N/A',
                profileLink:
                    user?.profileLink || 'N/A',
                developerType:
                    user?.developerType ||
                    'InHouse',
                group:
                    user?.group?.[0]?.groupName ||
                    'N/A',
                joiningDate: joinDate.isValid()
                    ? joinDate.format(
                          'DD/MM/YYYY',
                      )
                    : 'N/A',
                seniority:
                    user?.seniority || 'N/A',
                id: `${user?.email}_${user?._id}`,
                objId: user?._id,
                isAvailable:
                    user?.isAvailable !== null
                        ? user?.isAvailable
                        : 'N/A',
                isDeleted: user?.isDeleted,
                reportingPm: user?.pms?.length
                    ? user?.pms.join(', ')
                    : 'N/A',
                billability:
                    user?.billability || 'N/A',
                combineBillability:
                    combinedProjectInfo || [],
                isOnNoticePeriod:
                    user?.isOnNoticePeriod ||
                    false,
                departureOn:
                    user?.departureOn || 'N/A',
                reportingManager:
                    user?.reportingManager?.[0]
                        ?.fullName || 'N/A',
                engagementType:
                    temporaryEngagement,
            };
        });

    const closeEditDevHandler = () => {
        setOpenEditDevModal(false);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleActionModal = () => {
        setOpenActionModal(false);
    };

    const deleteDeveloper = () => {
        deleteQuery =
            deleteQuery +
            userId +
            '/' +
            deleteDate;

        dispatch(
            delete_user({
                deleteQuery,
            }),
        ).then(() => {
            setIsSuccess(true);
            setOpenActionModal(false);
            toast(
                'Developer Departed Successfully',
            );

            dispatch(
                get_users(
                    'api/v2/users/search?isDeleted=false',
                ),
            );

            onClose();
        });
    };

    const handleRetainClick = async () => {
        await dispatch(
            retainDepartedDeveloper({
                action: 'retain',
                date: dayjs().format(
                    'DD-MM-YYYY',
                ),
                id: userId,
            }),
        );
        toast('Developer Retained Successfully');
        handleCloseConfirmDialog();
        const url = 'api/v2/users/search';
        // dispatch(
        //     search_user({
        //         url,
        //         data: searchFilters,
        //     }),
        // );
        dispatch(get_users);
    };

    const devStatusHandler = (value) => {
        setDeleted(value);

        dispatch(
            get_users(
                `api/v2/users/search?isDeleted=${value}`,
            ),
        );
    };

    const [
        deleteDeveloperName,
        setDeleteDeveloperName,
    ] = useState('');

    return (
        <Box
            sx={{
                height: '85vh',
                background: 'white',
                borderRadius: '16px',
                paddingBottom: '4px',
            }}
        >
            {deleted === false && (
                <DeveloperFilter
                    status={deleted}
                    devStatusHandler={
                        devStatusHandler
                    }
                    setDeveloperName={
                        setDeveloperName
                    }
                    developerName={developerName}
                    searchValue={searchValue}
                />
            )}

            {deleted === true && (
                <DeletedDevFilter
                    status={deleted}
                    devStatusHandler={
                        devStatusHandler
                    }
                    setDeveloperName={
                        setDeveloperName
                    }
                    developerName={developerName}
                />
            )}

            {openEditDevModal && (
                <EditDevModal
                    open={openEditDevModal}
                    onClose={closeEditDevHandler}
                    userData={userData}
                    setOpenActionModal={
                        setOpenActionModal
                    }
                />
            )}

            <div
                style={{
                    height: '73%',
                    width: '95%',
                }}
                className="mt-4 ml-4 pb-2"
            >
                {!isLoading ? (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        rowCount={totalCount}
                        stickyHeader
                        pagination
                        paginationMode="server"
                        pageSize={pageSize}
                        page={pageCount}
                        // onPageChange={(newPage) =>
                        //     dispatch(
                        //         setCurrentPage(
                        //             newPage,
                        //         ),
                        //     )
                        // }

                        onPageChange={(
                            newPage,
                        ) => {
                            setPageCount(newPage);

                            dispatch(
                                get_users({
                                    pageCount:
                                        newPage,
                                    pageSize,
                                    developerName:
                                        searchValue,
                                }),
                            );
                        }}
                        onPageSizeChange={(
                            newPageSize,
                        ) => {
                            setPageCount(0);
                            setPageSize(
                                newPageSize,
                            );
                            dispatch(
                                get_users({
                                    pageCount: 0,
                                    pageSize:
                                        newPageSize,
                                    developerName:
                                        searchValue,
                                }),
                            );
                        }}
                        rowsPerPageOptions={[
                            10, 25, 50, 100,
                        ]}
                        loading={searching}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack
                                    height="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    No Results
                                    Found
                                </Stack>
                            ),
                        }}
                        initialState={{
                            pinnedColumns: {
                                left: ['empId'],
                            },
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,

                                quickFilterProps:
                                    {
                                        debounceMs: 500,
                                    },
                            },
                        }}
                        sx={{
                            'backgroundColor':
                                'white',

                            '& .MuiDataGrid-columnSeparator':
                                {
                                    // display: "none",
                                },

                            '& .MuiDataGrid-columnHeaders':
                                {
                                    // fontSize: "1rem",

                                    background:
                                        '#FAFAFA',

                                    color: 'Black',
                                },

                            '& .MuiDataGrid-sortIcon':
                                {
                                    color: 'black',
                                },
                        }}
                        disableColumnMenu
                        rowHeight={40}
                        filterModel={{
                            items: [
                                {
                                    columnField:
                                        'fullName',
                                    operatorValue:
                                        'contains',
                                    // value: `${developerName}`,
                                },
                            ],
                        }}
                    />
                ) : (
                    <LoadingSpinner />
                )}
            </div>

            {openConfirmDialog && (
                <ConfirmationDialog
                    open={openConfirmDialog}
                    onClose={
                        handleCloseConfirmDialog
                    }
                    onConfirm={deleteDeveloper}
                    isSuccess={isSuccess}
                    developerDelete={
                        developerDelete
                    }
                    handleDeleteDate={
                        handleDeleteDate
                    }
                    isOnNoticePeriod={
                        isOnNoticePeriod
                    }
                    handleRetainClick={
                        handleRetainClick
                    }
                    deleteDeveloperName={
                        deleteDeveloperName
                    }
                    departureOn={departureOn}
                />
            )}

            {openActionModal && (
                <ActionModal
                    open={openActionModal}
                    onClose={handleActionModal}
                    onConfirm={deleteDeveloper}
                    isSuccess={isSuccess}
                    handleDelete={handleDelete}
                    setOpenActionModal={
                        setOpenActionModal
                    }
                    editModalHandler={
                        editModalHandler
                    }
                />
            )}
        </Box>
    );
};

export default Developer;
